<template>
    <div>
         <iframe v-if="VRurl" :src="VRurl" id="frameId" frameborder="0" :width="VRwidth" :height="VRheight"
                 style="transform: translate(-135px, 0);"></iframe>
    </div>
    
</template>

<script>
    import {getHomeInfo} from "../../utils/api";
    import getWXSign from "../../utils/wxSign";
    import wx from "weixin-js-sdk";
    import img from '../../assets/img/site/site.png'
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "VRIframe",
        data(){
            return {
                VRwidth: document.body.clientWidth +135 ,
                VRheight: document.body.clientHeight + 50,
                img,
                VRurl:''
            }
        },
        beforeRouteEnter (to, from, next) {
            console.log("进入路由vr beforeRouteEnter")
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // XXX: 修复iOS版微信HTML5 History兼容性问题
            if (isiOS && to.path !== location.pathname) {
                // 此处不可使用location.replace
                location.assign(to.fullPath)
                console.log("ios设备")
            } else {
                next()
            }
        },
        mounted() {
            this.VRurl = this.$route.query.VRURL
            getHomeInfo().then(res=>{
                if (res.code === 200 && res.data.businessCode === 200) {
                    let info = res.data.homePage.vr[0];
                    let imgUrl =info.url
                    console.log("tupian:",imgUrl)
                    console.log("desc:",info.desc)
                    getWXSign().then(()=>{
                        wx.updateAppMessageShareData({
                            title: info.name, //
                            desc: info.desc, // 分享描述
                            link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: imgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })
                        wx.updateTimelineShareData({
                            title: info.name, //
                            desc: info.desc, // 分享描述
                            link: location.href , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl:imgUrl, // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })

                    })
                }
            })
            uwStatisticAction('/VRIframe','VR全景')
        }
    }
</script>

<style scoped>

</style>